<script setup lang="ts">
import { showNotify } from 'vant'
import googleVerify from '~/components/googleVerify.vue'
import { MemberType } from '~/constants/member'

interface Form {
  uname: string
  pwd: string
}

const { query } = useRoute()
const { t } = useI18n()
const localePath = useLocalePath()
const { formatQuery } = useUseBeforeRequest()

const authStore = useAuthStore()
const cartStore = useCartStore()
const recaptcha = ref()

const form = ref<Form>({
  uname: '',
  pwd: '',
})

const formLoading = ref<boolean>(false)
const validateCode = ref(false)
const checkValidateCode = computed(() => validateCode.value)
const key = ref('6LemG-YmAAAAAALAEaca6Cq7Jw2O2Db8y2i_5Hnr')
// 人机验证
const getValidateCode = (value: any) => (validateCode.value = value)
async function onSubmit() {
  if (!checkValidateCode.value) {
    showToast(t('Please perform human-machine verification first'))
    return
  }
  formLoading.value = true
  try {
    const { data, success, msg } = await useHttp.post<UserStore>(
      `/ichi_api/wCustomer/login`,
      null,
      {
        params: {
          MESSAGE: formatQuery(form.value),
        },
      },
    )

    if (success) {
      await authStore.login(data!)
      cartStore.syncCart()
      const redirect = query.redirect as string

      navigateTo(localePath(redirect || '/'), { replace: true })
    }
    else {
      showNotify(msg!)
    }
  }
  catch (error: any) {
    console.error(error)

    showNotify(error.message | error.msg || error)
  }
  finally {
    formLoading.value = false
  }
}

const passwordVisible = ref<boolean>(false)
// 密码可见
function togglePasswordVisibility() {
  passwordVisible.value = !passwordVisible.value
}

const moreContent = ref()

const { data: seoData, status: seoStatus } = await useFetch<_Seo[]>('/api/seoRelation/list', {
  method: 'POST',
  body: {
    paths: [useRoute().path],
  },
})

watch(() => seoStatus.value, (newVal) => {
  if (newVal === 'success') {
    if (seoData.value && Array.isArray(seoData.value) && seoData.value.length) {
      const { content } = JSON.parse(JSON.stringify(seoData.value)).pop()
      moreContent.value = content.content
      const { title, meta } = content
      useHead({
        title,
        meta,
      })
    }
  }
}, {
  immediate: true,
})
</script>

<template>
  <section>
    <div>
      <img relative w-full src="~/assets/image/login-bg.png" alt="">
      <svgo-baking-supplies filled class="logo text-[150px]" />
    </div>
    <div mt-10>
      <van-form @submit="onSubmit">
        <van-field v-model="form.uname" name="phone" :placeholder="t('Login.phone')" autocomplete="off" required />
        <van-field
          v-model="form.pwd" :type="passwordVisible ? 'text' : 'password'" name="password"
          :placeholder="t('Login.password')" :rules="[
            {
              required: true,
              message: t('Login.passwordrules'),
              trigger: 'onSubmit',
            },
          ]" autocomplete="off" clearable
        >
          <template #right-icon>
            <van-icon :name="passwordVisible ? 'eye' : 'eye-o'" @click="togglePasswordVisibility" />
          </template>
        </van-field>
        <div class="ml-5">
          <googleVerify
            ref="recaptcha"
            v-model="validateCode"
            :sitekey="key"
            @get-validate-code="getValidateCode"
          />
        </div>

        <van-button color="#743a3a" class="submitButton" round native-type="submit" h-40px :loading="formLoading">
          <span font-size-16px font-500>{{ t("Login.login") }}</span>
        </van-button>
        <div mx-5 mt-3 flex justify-between font-size-14px class="text-[#743a3a]">
          <NuxtLink :to="localePath('/register')">
            {{ t("Login.newUser") }}
          </NuxtLink>
          <NuxtLink :to="localePath(`/password`)">
            {{ t("Login.Forgot password") }}
          </NuxtLink>
        </div>
      </van-form>
    </div>
    <app-tabbar />
  </section>
</template>

<style scoped lang="scss">
  .logo {
  position: absolute;
  top: 17%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 60%;
}

.submitButton {
  width: 95%;
  margin: 20px 10px 0 10px;
}

:deep(.van-cell:after) {
  border: none;
}

:deep(.van-field__body) {
  background: #f6f6f6;
  border-radius: 20px;
  padding: 10px;
}
</style>
